<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
// import axios from "axios";
import StarRating from "vue-star-rating";

export default {
  page: {
    title: "Drivers",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader,StarRating },
  data() {
    return {
      // driver:null,
      star:null,
      title: "Driver Details",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Drivers",
          href: "JavaScript:void(0)"
        },
        {
          text: "Driver Details",
          active: true
        }
      ],
    }
  },
  mounted() {
   
    this.$store.dispatch('driver/getDriversforDetail')

    if(this.driver.rating === 0){
      this.star=0
    }else{
      this.star=this.driver.rating/this.driver.completed_rides
    }
   
    

  },

  computed:{
    driver(){
      return this.$store.getters['driver/driverGetter'](this.$route.params.id)
    }
  },

  methods:{
    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },
  }
  
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card profile-card">
          <div class="card-body">
            <img alt="200x200" v-if="driver.photo_url !== null" :src='driver.photo_url' data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl">
            <img alt="200x200" v-if="driver.photo_url === null" src='../../../assets/images/users/dummy-avatar.jpg' data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl">
            <div class="profile-text">
              <h5 class="mb-0">{{driver.first_name}} {{driver.last_name}}</h5>
              <div class="stars">
                <div class="text-center">
                  <star-rating :rating=star :read-only="true" :star-size="15"></star-rating>
                </div>
              </div>
            </div>
            <!-- <p class="profile-status" :class="{'text-success': `${driver.status.active_status}` === 'active', 'text-danger': `${driver.status.active_status}` === 'inactive'}"><i class="ri-checkbox-blank-circle-fill"></i> {{ driver.status.active_status }}</p> -->
<!--            <a href="javascript: void(0);" target="_self" class="btn btn-danger profile-button">De-Activate</a>-->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <tbody>
                  <tr>
                    <th scope="row"> Name</th>
                    <td class="text-right">{{ driver.first_name }} {{ driver.last_name }}</td>
                  </tr>
                 
                  <tr>
                    <th scope="row">Phone Number</th>
                    <td class="text-right">{{ driver.phone_number }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td class="text-right">{{ driver.email_address }}</td>
                  </tr>
                  <tr>
                    <th scope="row">State</th>
                    <td class="text-right"> {{ driver.state}} </td>
                  </tr>
                 
                  
                  <tr>
                    <th scope="row">Account Status</th>
                    <td class="text-right">{{driver.status.account_status}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Created At</th>
                    <td class="text-right">{{ convertDate(driver.createdAt) }} {{ convertTime(driver.createdAt) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Current Status</th>
                    <td class="text-right">{{ driver.status.active_status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>

<style scoped>
  .profile-card {
    position: relative;
  }
  .profile-text {
    position: absolute;
    top: 36%;
    left: 165px;
  }
  .profile-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .profile-status {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .profile-status i {
    font-size: 12px !important;
  }
</style>